@import "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/reportsFilterForm.module";

.base {
  @include choiceGroupBase;
}

.title {
  @include choiceGroupHeader;
}

.checkbox {
  @include choiceGroupCheckbox;
}

.checkboxLabel {
  @include choiceGroupCheckboxLabel;
}

.checkboxLabelChecked {
  @include choiceGroupCheckboxLabelChecked;
}

.bottomElementInfo {
  @include p(2, 3);

  background-color: $color-ui-7;
  border-top: 1px solid $color-ui-6;
  color: $color-ui-3;
  display: block;
  font-size: 15px;
  font-weight: bold;
}
