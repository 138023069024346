@import "styles/index";

.wrapper {
  @include flex($align: center);
}

.label {
  font-weight: bold;
  display: block;
  margin-right: 15px;
}
