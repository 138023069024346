@import "styles/index.scss";

.item {
  @include inline-flex($align: center);

  line-height: 24px;

  &:not(:last-child) {
    padding-right: 8px;
  }
}

.label {
  text-align: initial;
  font-size: 15px;
  color: $color-ui-15;
}

.grey {
  opacity: 0.4;
}
