@import "styles/index";

.headerWrapper {
  @include flex(row, center, center);

  position: relative;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 10px;
  border: 0;
  font-weight: bold;
  font-size: 16px;
  color: $color-ui-3;
  cursor: pointer;
  background-color: transparent;
}

.contentWrapper {
  margin-top: 32px;
}

.header {
  font-size: 24px;
  font-weight: 600;
  color: $color-ui-1;
  line-height: 1;
}

.content {
  margin-top: 24px;
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: $color-ui-3;
}
