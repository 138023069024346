@import "styles/index";

.text {
  font-size: 12px;
  fill: $color-ui-15;
  cursor: pointer;
}

.sunday {
  fill: $color-ui-18;
  font-weight: 600;
}
