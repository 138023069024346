$color-ui-1: #000;
$color-ui-2: #515151;
$color-ui-3: #74859f;
$color-ui-4: #6d89b6;
$color-ui-5: #a6b7d3;
$color-ui-6: #e1e8f2;
$color-ui-7: #f5f7fa;
$color-ui-8: #006420;
$color-ui-9: #43b649;
$color-ui-10: #77abff;
$color-ui-11: #ca1d08;
$color-ui-12: #ffe6e3;
$color-ui-13: #fff;
$color-ui-14: #909090;
$color-ui-15: #888889;
$color-ui-16: #cdd6e5;
$color-ui-17: #cad3e5;
$color-ui-18: #fb4934;
$color-ui-19: transparentize($color-ui-9, 0.34);
$color-ui-20: transparentize($color-ui-17, 0.63);
$color-ui-21: #e3e3e3;
$color-ui-22: transparentize($color-ui-13, 0.3);
$color-ui-23: transparentize(#1d1d1d, 0.3);
$color-ui-24: #eaedf3;
$color-ui-25: #00682b;
$color-ui-26: #c9d9f0;
$color-ui-27: transparentize($color-ui-25, 0.8);
$color-ui-28: #d1dae8;
$color-ui-29: #d2dbe9;
$color-ui-30: transparentize($color-ui-13, 0.2);
$color-ui-31: #8f8f8f;
$color-ui-32: #d0daee;
$color-ui-33: #e4e9f2;
$color-ui-34: #878787;
$color-ui-35: #eaedf3;
$color-ui-36: #5a65d9;
$color-ui-37: #ccc;
$color-ui-38: #e9e9f1;
$color-ui-39: #a6a19f;
$customScrollbar: #00000080;

$color-chart-1: #5a65d9;
$color-chart-2: #ffa644;
$color-chart-3: #ca1d08;
$color-chart-4: #754198;
$color-chart-5: #e167a9;
$color-chart-6: #54c4ff;
$color-chart-7: #d8d8d8;

$sidebarWidth: 304px;
$linksNavbarHeight: 66px;
$filtersAndPromotionsBarsTransitionTime: 0.65s;
$springLikeTransition: cubic-bezier(0.16, 1, 0.3, 1);
$linksNavbarHeight: 65px;

$loginFormCardBorder: solid 1px #cad3e5;
$defaultBoxShadow: 0 0 8px 0 $color-ui-20;
$TOGGLE_BORDER_RADIUS: 12px;

$bp-xs: 0;
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1440px;
$bp-popular: 1537px;
