@import "styles/mixins";
@import "styles/modules/constants";

$box-shadow: 0 6px 30px 5px rgba(56, 69, 93, 0.12),
  0 16px 24px 2px rgba(56, 69, 93, 0.14), 0 0 0 1px rgba(146, 155, 170, 0.12);

.successAlert {
  @include flex($align: center);

  position: fixed;
  top: 20px;
  right: 20px;
  width: auto;
  max-width: 90vw;
  height: auto;
  z-index: 12;
  padding: 15px;
  box-shadow: $box-shadow;
  background-color: $color-ui-9;
  line-height: 1.2;
}

.successInterior {
  @include flex($align: center);

  padding: 15px;
  font-size: 17px;
  color: $color-ui-13;
}

.closeIcon {
  color: $color-ui-13;
  min-width: 20px;
}
