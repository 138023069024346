@import "styles/mixins/index.scss";

.base {
  position: fixed;
  z-index: 6;
  height: 100%;
  width: $sidebarWidth;
  left: -$sidebarWidth;
  transition: left $filtersAndPromotionsBarsTransitionTime $springLikeTransition;
  background-color: $color-ui-13;

  @media (max-width: $bp-xl) {
    // overriding padding injected on scroll due to sticky nav behaviour
    padding-bottom: 0 !important;
  }
}

.open {
  left: 0;
  border-right: 1px solid $color-ui-6;
}

.button {
  @include buttonPrimary;
  @include sidebarToggleButton;
  @include position(absolute, $top: 14px, $left: 100%);
  @include halfButton(right);

  padding: 0;

  & svg {
    @include sidebarToggleButtonIcon;
  }
}

.buttonOpen {
  @include buttonSecondary;

  left: calc(100% + 1px) // + 1px used to take into account sidebar's border-right
}

.buttonText {
  @include buttonTextPrimary;

  font-size: 14px;
  margin-left: 30px;
}

.bodyWrapper {
  height: calc(100% - 150px);
  // workaround for this issue https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
  position: relative;
  overflow-x: visible;
  overflow-y: visible;

  @media (min-width: $bp-xl) {
    height: 100%;
  }
}

.body {
  position: absolute;
  padding: 0 0 24px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: visible;
}
