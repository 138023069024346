@import "styles/index";

$border: 1px solid $color-ui-21;
$radius: 10px;
$padding: 8px 10px;
$link-height: 36;
$link-padding: 24;

.overlay {
  @include p(2);
}

.link {
  @include flex;
  @include buttonPrimary;
  @include buttonTextPrimary;
  @include m(2, auto, 0, auto);

  width: max-content;
  padding: 10px 12px 10px 22px;
  border-radius: 99px;
  text-decoration: none;

  svg {
    transform: translateY(-1px);
  }
}

.container {
  @include p(3);

  width: 800px;
  margin: 0 auto;
  border-radius: 14px;
  background-color: $color-ui-13;
  max-height: calc(100vh - 20px);
  overflow-y: auto;
  transition: width 0.5s ease;

  &.fullWidth {
    width: 100%;
    padding-bottom: #{$link-height + $link-padding}px;

    .link {
      position: fixed;
      bottom: #{$link-padding}px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.navigation {
  @include flex($align: flex-start, $justify: space-between);

  width: 100%;
}

.headingWrapper {
  @include flex($align: center);
}

.index {
  @include mr(2);

  flex-shrink: 0;
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 30px;
  border-radius: 50%;
  font-weight: 600;
  text-align: center;
  color: $color-ui-25;
  background-color: transparentize($color-ui-9, 0.7);
  box-shadow: $defaultBoxShadow;
}

.heading {
  font-size: 20px;
  font-weight: bold;
  color: $color-ui-1;
}

.buttons {
  @include flex;

  flex-shrink: 0;
}

.paginationButton {
  @include flex;
  @include mr(2);

  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: $color-ui-3;

  &:hover {
    color: darken($color-ui-3, 15%);
  }

  &:disabled {
    cursor: not-allowed;
    color: $color-ui-14;
  }
}

.closeButton {
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: $color-ui-3;

  &:hover {
    color: darken($color-ui-3, 15%);
  }
}

.table {
  @include mt(2);

  text-align: left;
  color: $color-ui-2;
  border-collapse: separate;
  width: 100%;
  table-layout: fixed;

  thead th {
    padding: $padding;
    border: 1px solid $color-ui-6;
    background-color: $color-ui-6;
    font-weight: 600;
    white-space: pre-wrap;

    &.change {
      width: 160px;
    }

    &.percentageChange {
      width: 100px;
    }

    &:first-child {
      border-top-left-radius: $radius;
      width: 180px;
    }

    &:last-child {
      border-top-right-radius: $radius;
    }
  }

  tbody td {
    padding: $padding;
    border-bottom: $border;

    &:first-child {
      border-left: $border;
    }

    &:last-child {
      border-right: $border;
    }
  }

  tbody tr {
    transition: background-color 0.1s ease;

    &:nth-child(even) {
      background-color: $color-ui-7;
    }

    &.headerRow td {
      border-top: $border;
    }

    &:last-child td {
      &:first-child {
        border-bottom-left-radius: $radius;
      }

      &:last-child {
        border-bottom-right-radius: $radius;
      }
    }

    &.headerRow td:first-child {
      border-top-left-radius: $radius;
    }

    &.headerRow td:last-child {
      border-top-right-radius: $radius;
    }

    &:hover {
      background-color: $color-ui-6;
    }
  }
}

.changeArrow {
  @include mr(1);

  transform: translateY(1px);

  &.positive {
    color: $color-ui-9;
  }

  &.negative {
    color: $color-ui-11;
  }
}

.promotion {
  @include p(2);

  border-radius: 5px;
  border: 1px solid rgba($color-ui-4, 0.2);
  background-color: $color-ui-13;
  box-shadow: 0 1px 4px 0 rgba($color-ui-4, 0.15);

  &:not(:last-child) {
    @include mb(1);
  }
}

.promotionDisplay {
  font-weight: 600;
}

.promotionVendor {
  @include mt(1);

  display: block;
  font-size: 14px;
  font-weight: 600;
}

.promotionProducts {
  font-size: 14px;
  color: $color-ui-15;
}
