@import "styles/mixins/index";

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 2px;
  max-width: 10px;
  min-width: 10px;
  background-color: $color-ui-9;
  flex-shrink: 0;
}
