@import "styles/index";

.button {
  @include buttonPrimary;
  @include buttonTextPrimary;
}

.expanded {
  @include mb(3);
}
