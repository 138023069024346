@import "styles/index";
@import "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/reportsFilterForm.module";

$border: 1px solid $color-ui-16;
$container-padding: 10px;

.multiMenu {
  @include pt(3);

  background-color: $color-ui-13;
  width: max-content;
  min-width: 320px;
  max-width: calc(100vw - 40px);
  overflow: hidden;
  box-shadow: 0 4px 16px 2px rgba(56, 69, 93, 0.14);
  border: $border;
  border-radius: 10px;
}

.closeButton {
  @include resetButton;
  @include ml(auto);

  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;

  svg {
    color: $color-ui-2;
  }
}

.listWrapper {
  @include flex($justify: space-around);

  min-width: 600px;
  padding: $container-padding;
}

.bottomWrapper {
  @include flex($justify: space-between);

  padding: $container-padding;
  border-top: $border;
}

.acceptButton {
  @include buttonPrimary;
  @include buttonTextPrimary;

  font-size: 14px;
  min-width: 100px;
}
