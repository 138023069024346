@import "styles/index";

$c-mine-shaft: #484848;
$c-boulder: #757575;
$c-silver-sand: #cacccd;

.single-date-calendar-popper {
  margin-bottom: 1em;
}

.single-date-calendar-input {
  padding: 10px 25px;
  font-size: 15px;
  color: $c-mine-shaft;
  border: 1px solid $color-ui-16;
  border-radius: 99px;
  width: 100%;
}

.single-date-calendar {
  .react-datepicker {
    &__input-container input {
      box-sizing: border-box;
      padding: 10px 25px;
      color: $color-ui-2;
      border: 1px solid $color-ui-16;
      border-radius: 99px;
    }

    &__month-container {
      padding: 1.25em;
      background-color: $color-ui-13;
      border: 1px solid $color-ui-29;
      border-radius: 10px;
      box-shadow: 0 4px 16px 2px rgba(56, 69, 93, 0.14);
    }

    &__nav {
      position: absolute;
      top: 20px;
      padding: 0;
      border: 0;
      background-color: transparent;
      color: $c-mine-shaft;
      cursor: pointer;

      &--prev {
        left: 30px;
      }

      &--next {
        right: 30px;
      }

      &--disabled {
        cursor: not-allowed;
        opacity: 0.2;
      }
    }

    &__current-month {
      display: block;
      font-weight: bold;
      text-align: center;
      text-transform: capitalize;
      color: $c-mine-shaft;
    }

    &__month {
      width: unset;
    }

    &__day-names {
      @include m(2, 0);
      @include pb(2);

      border-bottom: 1px solid $color-ui-29;
    }

    &__day-name {
      @include flex($align: center, $justify: center);

      width: 39px;
      height: 28px;
      font-size: 14px;
      text-transform: capitalize;
      color: $c-boulder;
    }

    &__day-names,
    &__week {
      @include flex;
    }

    &__day {
      @include flex($align: center, $justify: center);

      font-size: 14px;
      color: $c-boulder;
      width: 39px;
      height: 28px;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: $color-ui-5;
        color: $color-ui-13;
      }

      &--today {
        border: 1px solid $color-ui-5;
      }

      &--disabled {
        cursor: not-allowed;
        color: $c-silver-sand !important;
        background-color: $color-ui-13 !important;
      }

      &--outside-month {
        visibility: hidden;
      }

      &--in-range {
        border-top: 1px solid $color-ui-5;
        border-bottom: 1px solid $color-ui-5;
        background-color: $color-ui-7 !important;
        color: $color-ui-3 !important;
        border-radius: 0;
      }

      &--range-start {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &--range-end {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      &--range-start,
      &--range-end,
      &--selected {
        color: $color-ui-13 !important;
        background-color: $color-ui-9 !important;
        border-color: $color-ui-9;
      }
    }
  }

  &--month-picker {
    .react-datepicker {
      &__monthPicker {
        @include mt(3);
      }

      &__month-wrapper {
        @include flex;
        @include mt(1);

        min-width: 200px;
      }

      &__month-text {
        @include flex($align: center, $justify: center);

        cursor: pointer;
        color: $color-ui-2;
        border: 1px solid $color-ui-16;
        border-radius: 5px;
        width: 100%;
        height: 32px;
        transition: background-color 0.1s ease-in;

        &:not(:last-child) {
          @include mr(1);
        }

        &:not(.react-datepicker__month--disabled):hover {
          background-color: $color-ui-33;
        }
      }

      &__month {
        &--selected,
        &--in-range {
          background-color: $color-ui-33;
        }

        &--disabled {
          cursor: not-allowed;
          opacity: 0.2;
        }
      }
    }
  }
}
