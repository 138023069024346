@import "styles/modules/constants";
@import "styles/mixins";

$dropdown-shadow: 0 4px 16px 2px rgba(56, 69, 93, 0.14);

.wrapper {
  position: relative;
}

.dropdownButton {
  border: 0;
  background-color: transparent;
  padding: 2px 0 0;
  cursor: pointer;
  color: $color-ui-3;
  font-weight: bold;
  font-size: 14px;

  &:hover {
    color: $color-ui-2;

    .dropdownIcon {
      path {
        fill: $color-ui-9;
      }
    }
  }
}

.dropdownWrapper {
  @include position(absolute, $top: 30px, $right: -10px);

  width: 250px;
  border: 1px solid $color-ui-29;
  background-color: $color-ui-13;
  box-shadow: $dropdown-shadow;
  border-radius: 10px;
  z-index: 4;
}

.dropdownElement {
  position: relative;
  height: 37px;
  border-top: 1px solid $color-ui-16;
  padding: 0 12px;
  color: $color-ui-3;
  font-size: 14px;
  cursor: pointer;
  font-weight: normal;

  &:first-child {
    border-top: 0;
  }

  &:hover {
    font-weight: bold;
    background-color: $color-ui-7;
    color: $color-ui-2;

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.downloadLink {
  @include flex($align: center);

  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
  border: 0;
}

.downloadText {
  color: inherit;
  font-weight: inherit;
  font-size: 14px;
}

.dropdownHeader {
  font-size: 12px;
  font-weight: normal;
  color: $color-ui-3;
  padding: 12px 0 9px 14px;
  text-align: left;
  cursor: default;
}

.sellHeaderReportLoader {
  transform: scale(0.6);
  margin: 0 5px -5px 0;
}

.dropdownButtonDisabled {
  @include defaultTooltip(attr(data-tooltip));

  cursor: not-allowed;

  &:hover::after {
    top: unset;
    bottom: calc(100% + 10px);
    width: 150px;
    white-space: normal;
  }
}
