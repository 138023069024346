@import "styles/index";

@mixin smallGreyText {
  color: $color-ui-3;
  font-size: 12px;
}

.container {
  border-radius: 14px;
  padding: 32px;
  max-width: 560px;
}

.headerContainer {
  @include flex($justify: space-between);
}

.heading {
  font-size: 24px;
}

.closeButton {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.closeIcon {
  color: $color-ui-3;
}

.inputWrapper {
  margin-top: 32px;
}

.labelText {
  font-size: 15px;
  margin-bottom: 8px;
}

.rating {
  margin-bottom: 8px;
}

.ratingScaleTextContainer {
  @include flex($justify: space-between);
}

.ratingScaleText {
  @include smallGreyText();
}

.optionalText {
  @include smallGreyText();

  margin-bottom: 8px;
}

.toggleButtonLi {
  min-width: 46px;
}

.inputText {
  min-height: 80px;
}

.buttonsWrapper {
  @include flex($align: center, $justify: flex-end);

  margin-top: 30px;
}

.actionButton {
  @include buttonPrimary;
  @include flex($align: center, $justify: center);

  color: $color-ui-13;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 10px 6px 25px;
  border-radius: 30px;
}

.cancelButton {
  border: 0;
  margin-right: 40px;
  font-weight: bold;
  font-size: 16px;
  color: $color-ui-3;
  cursor: pointer;
  background-color: transparent;
}

.errorText {
  color: $color-ui-18;
  margin: 5px 0;
}
