@import 'styles/modules/constants.scss';
@import 'styles/index.scss';

.base {
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.h2 {
  font-size: 28px;
  color: $color-ui-1;
}

.h3 {
  font-size: 24px;
  color: $color-ui-1;
}

.h4 {
  font-size: 20px;
  color: $color-ui-1;
}

.h5 {
  font-size: 16px;
  color: $color-ui-2;
}

.mainNumber {
  font-size: 40px;
  color: $color-ui-2;
}

.semiNumber {
  font-size: 16px;
  font-weight: normal;
  color: $color-ui-14;
}

.smallNumber {
  font-size: 12px;
  font-weight: normal;
  color: $color-ui-15;
}
