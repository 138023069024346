@import "styles/index.scss";

.base {
  flex-direction: row-reverse;

  &:not(:last-child) {
    @include mr(3);
  }

  &:not(:last-child)::after {
    @include separator;
  }
}

.checkbox {
  @include mr(0);
}

.label {
  color: $color-ui-3;
  font-size: 15px;
  font-weight: normal;
}
