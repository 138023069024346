@import "styles/index.scss";

.checkbox {
  @include mr(2);
}

.label {
  color: $color-ui-3;
  font-size: 15px;
  font-weight: normal;
}
