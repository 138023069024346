@import "styles/index";

.overlay {
  padding: 20px;
}

.container {
  position: relative;
  display: grid;
  grid-template-columns: 60% 40%;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 42px 48px;
  border-radius: 14px;
  background-color: $color-ui-13;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}

.closeButton {
  @include resetButton;
  @include position(absolute, $top: 18px, $right: 18px);

  cursor: pointer;
  color: $color-ui-2;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: $color-ui-1;
}

.text {
  @include my(2);

  color: $color-ui-2;
  line-height: 1.3;
}

.subtitle {
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
}

.list {
  @include ml(3);
  @include mb(3);

  list-style-type: decimal;

  li:not(:last-child) {
    @include mb(1);
  }
}

.buttonPrimary {
  @include buttonPrimary;
  @include buttonTextPrimary;
  @include m(0, auto, 1);

  display: block;
}

.buttonSecondary {
  @include resetButton;
  @include m(0, auto);

  display: block;
  cursor: pointer;
  color: $color-ui-9;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.image {
  width: 100%;
  height: auto;
  object-fit: contain;
}
