$time: 0.25s;
$opacityTransition: ease-in-out;

.tileBox {
  display: inline-block;
  padding: 2px 5px 1px;
  border-radius: 5px;
  transform: translate(-50%, -130%);
  transition: opacity $time $opacityTransition;
}
