@import "styles/index";

.cellTooltipBody {
  @include flex($justify: center);

  width: 100%;
}

.tableWrapper {
  border: 1px solid $color-ui-6;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.table {
  color: $color-ui-2;
  font-size: 14px;
  width: 100%;

  th {
    @include p(1);

    background-color: $color-ui-6;
    font-weight: 600;
  }

  tr:nth-child(even) {
    background-color: $color-ui-7;
  }

  td {
    @include p(1);

    vertical-align: middle;
  }

  .historical {
    color: $color-ui-3;
    font-size: 12px;
  }
}
