@import "styles/index";

.primaryNavbar {
  @include flex($align: center, $justify: space-between);

  background-color: $color-ui-13;
  padding: 10px 32px;
}

.primaryNavbarContent {
  @include flex($align: center);
}

.logoLink {
  width: 80px;
  height: 32px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.menu {
  @include flex;
}

.menuItem {
  @include position(relative);
}

.menuLink {
  display: block;
  padding: 24px 32px;
  font-weight: 600;
  color: $color-ui-2;
  text-decoration: none;
  transition: color 0.1s ease;

  &:hover:not(.menuLinkActive):not(.secondaryMenuLinkActive) {
    color: darken($color-ui-9, 15%);
  }
}

.menuLinkActive {
  color: $color-ui-9;
}

.secondaryMenuLinkActive {
  color: $color-ui-9;
  box-shadow: inset 0 -2px 0 0 $color-ui-9;
}

.badge {
  @include position(absolute, $top: 0, $right: 0);

  font-size: 12px;
  font-weight: 600;
  color: $color-ui-13;
  background-color: $color-ui-18;
  border-radius: 2px;
  text-transform: uppercase;
  padding: 4px 5px 3px;
}

.email {
  display: block;
  margin-right: 10px;
}

.settingsButton {
  width: 24px;
  height: 24px;
  margin-right: 12px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.logoutButton {
  @include buttonPrimary;
  @include buttonTextPrimary;
}

.secondaryNavbar {
  background-color: $color-ui-13;
  border-bottom: 1px solid $color-ui-24;
  width: 100%;
  z-index: 7;
}

.secondaryNavbarFixed {
  @include position(fixed);
}
