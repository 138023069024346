@import "styles/index";

.popupWrapper {
  @include p(4);

  max-width: 97vw;
  width: max-content;
  height: auto;
  max-height: 97vh;
  border-radius: 14px;
  background-color: $color-ui-13;
}

.topLineWrapper {
  @include flex($justify: space-between, $align: center);
  @include mb(2);
}

.downloadButton {
  @include buttonPrimary;

  color: $color-ui-13;
  font-size: 14px;
  font-weight: bold;
}

.downloadButtonDisabled {
  &:hover {
    cursor: not-allowed;
  }
}

.closeButton {
  @include resetButton;

  width: 2rem;
  height: 2rem;
}

.table {
  color: $color-ui-2;
  border: 1px solid $color-ui-21;
  border-radius: 14px;
  overflow: hidden;
}

.tableRow {
  display: grid;
  background-color: $color-ui-7;

  &:not(:last-child) {
    border-bottom: 1px solid $color-ui-21;
  }
}

.tableRowEven {
  background-color: $color-ui-13;
}

.tableCell {
  @include flex($align: center);

  padding: 0 20px 0 16px;

  &:first-child {
    padding-left: 32px;
  }

  &:last-child {
    padding-left: 0;
    padding-right: 32px;
  }
}

.tableHeader {
  padding-top: 24px;
  padding-bottom: 16px;
  background-color: $color-ui-6;
}

.tableHeaderButton {
  cursor: pointer;
  position: relative;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-weight: bold;
  font-size: 16px;
  color: $color-ui-2;

  svg {
    position: absolute;
    top: 50%;
    right: -18px;
    transform: translateY(-50%);
  }
}
