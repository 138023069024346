@import "styles/index.scss";
@import "styles/mixins";

.popupWrapper {
  @include p(5);

  width: 80vw;
  height: 90vh;
  overflow: scroll;
}

.topLineWraper {
  @include flex($justify: flex-end, $align: center);
  @include mb(2);
}

.closeButton {
  @include resetButton;
}

.paragraph {
  @include mt(3);
}

.underline {
  text-decoration: underline;
}

.tableResponsiveWrapper {
  @include mt(3);

  overflow: visible;
}

.bold {
  font-weight: bold;
}
