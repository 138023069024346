@import "styles/index";

.button {
  @include resetButton;
  @include my(1);

  display: block;
  border-radius: 0;
  min-width: auto;
  min-height: auto;
  color: $color-ui-9;
  white-space: nowrap;
  text-decoration: underline;
  position: relative;

  &:hover {
    text-decoration: none;
  }

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    color: $color-ui-14;

    &:hover {
      text-decoration: underline;
    }
  }
}
