@import "styles/index.scss";

.header {
  padding-top: 17px;
  font-size: 18px;
  letter-spacing: 1.1px;
  color: $color-ui-8;
}

.textWrapper {
  margin: 30px 0 20px;
}

.regularText {
  font-size: 20px;
  text-align: center;
  display: block;
}

.textGap {
  margin: 0 0 20px;
}
