@import "styles/index.scss";

.radio {
  width: 16px;
  height: 16px;
  border-radius: 20px;
  border: solid 1px $color-ui-16;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;
  position: relative;
  margin-right: 12px;
  flex-shrink: 0;

  & input[type="radio"] {
    visibility: hidden;

    & + div {
      opacity: 0;
    }

    &:checked + div {
      opacity: 1;
    }
  }
}

.base {
  @include flex($align: center);

  cursor: pointer;

  .radio.checked {
    border-color: $color-ui-9;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.disabled .radio,
  &.disabled .label {
    opacity: 0.4;
  }

  &:hover .radio:not(.disabled),
  &:focus .radio:not(.disabled) {
    border-color: darken($color-ui-16, 15%);
  }

  &:active .radio:not(.disabled) {
    border-color: darken($color-ui-16, 5%);
  }

  &:hover .radio.checked:not(.disabled),
  &:focus .radio.checked:not(.disabled) {
    border-color: darken($color-ui-9, 15%);
  }

  &:active .radio.checked:not(.disabled) {
    border-color: darken($color-ui-9, 5%);
  }
}

.tooltip {
  @include defaultTooltip(attr(data-tooltip));

  position: relative;

  &:hover::after {
    top: unset;
    bottom: calc(100% + 5px);
  }
}

.tick {
  @include position(absolute, $top: 4px, $left: 4px);

  border-radius: 20px;
  background-color: $color-ui-9;
  width: 6px;
  height: 6px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.tooltipWrapper {
  @include flex($align: center);
}

.iconTooltipWrapper {
  @include defaultTooltip(attr(data-tooltip));

  margin-left: 2px;
  cursor: pointer;
  position: relative;
  max-height: 1rem;

  &:hover::after {
    top: auto;
    bottom: calc(100% + 10px);
    width: auto;
    min-width: 200px;
    z-index: 6;
  }
}

.tooltipIcon {
  transform: scale(0.7);
  margin-top: -0.2rem;
}
