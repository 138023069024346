@import "styles/index";

.dotsIcon {
  @include position(absolute, $left: -2px, $top: 51%);

  opacity: 0;
  transition: opacity 0.6s $springLikeTransition;
  color: $color-ui-15;
}

.resizeHandleClosed {
  cursor: default !important;
}

.resizeHandleOpen {
  position: relative;
  width: 15px;
  height: 100%;

  &::before {
    content: "";

    @include position(absolute, $left: 3px, $top: 1%);

    height: 98%;
    width: 2px;
    border-radius: 10px;
    background-color: $color-ui-9;
    opacity: 0;
    transition: opacity 0.6s $springLikeTransition;
  }

  &:hover::before {
    opacity: 0.8;
  }

  .dotsIcon {
    opacity: 1;
  }
}

.resizeHandleClicked {
  &::before {
    opacity: 0.8;
  }
}
