@import "styles/index";
@import "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/reportsFilterForm.module";

.categoriesWrapper {
  @include flex($direction: column);

  width: 100%;
  margin: 0 5px;
}

.categoryLabelWrapper {
  @include flex($align: center);
  @include mb(2);
}

.categoryLabel {
  font-weight: bold;
  cursor: pointer;
}

.referenceCategories {
  margin-bottom: 15px;
}

.categoryItemText {
  padding: 3px 0;
}

.categoryItemsBase {
  border: 0;
  padding: 0;
  min-height: 0;
}

.checkboxLabel {
  @include choiceGroupCheckboxLabel;

  color: $color-ui-2;
  font-size: 16px;
}

.checkboxLabelChecked {
  @include choiceGroupCheckboxLabelChecked;
}

.checkboxWrapper {
  @include flex($justify: flex-start, $align: center);
}
