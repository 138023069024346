@import "styles/index";

.wrapper {
  @include flex($direction: column);

  padding-top: 20px;
  border-left: 1px solid $color-ui-29;
}

.heading {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: $color-ui-2;
  padding: 0 32px;
  margin-bottom: 10px;
}

.tooltipIconWrapper {
  @include flex($align: center);
  @include defaultTooltip(attr(data-tooltip));

  position: relative;

  &:hover::after {
    width: 170px;
    top: auto;
    bottom: calc(100% + 5px);
    right: 0;
    transform: translateX(0);
  }

  & svg {
    cursor: pointer;
    transform: scale(0.7);
  }
}

.button {
  @include flex($align: center);

  cursor: pointer;
  width: 100%;
  font-size: 14px;
  color: $color-ui-3;
  text-align: left;
  padding: 6px 6px 6px 32px;
  background-color: $color-ui-13;
  border: 0;
  border-top: 1px solid $color-ui-13;
  border-bottom: 1px solid $color-ui-13;

  svg {
    margin-right: 10px;
    color: $color-ui-9;
    opacity: 0;
    transform: translate(0, -1px);
  }

  &.active {
    font-weight: 600;
    color: $color-ui-2;

    svg {
      opacity: 1;
    }
  }

  &:disabled {
    @include defaultTooltip("Brak danych dla tego okresu");

    cursor: not-allowed;
    color: lighten($color-ui-3, 25%);
    position: relative;

    &:hover::after {
      top: -100%;
      font-weight: bold;
      background-color: $color-ui-11;
      pointer-events: none;
      width: max-content;
    }
  }

  &:hover:not(:disabled),
  &.active {
    background-color: $color-ui-7;
    border-top-color: $color-ui-29;
    border-bottom-color: $color-ui-29;
  }
}

.checkboxWrapper {
  @include flex;
  @include p(3);

  margin-top: auto;
}
