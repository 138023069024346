@import 'styles/modules/constants.scss';

.text {
  font-size: 12px;
  color: $color-ui-15;
}

.grid line {
  stroke: $color-chart-7;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
  stroke-width: 1;
  fill: transparent;
  fill-rule: evenodd;
  stroke-dasharray: 2;
  stroke-linecap: square;
}

.grid path {
  stroke-width: 0;
}

.zeroTextTick {
  color: $color-ui-5;
  font-weight: bold;
}
