@import "styles/index.scss";

.unactiveProductsCheckbox {
  @include p(2, 3);

  border-top: 1px solid $color-ui-6;
  background-color: $color-ui-7;
}

.unactiveProductsLabel {
  color: $color-ui-3;
  font-size: 15px;
  font-weight: bold;
}
