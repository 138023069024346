@import "styles/index";

.daysWrapper {
  padding: 25px;
  border-left: 1px solid $color-ui-29;
}

.dayCheckboxBase {
  padding-bottom: 15px;
}

.daysTitle {
  font-size: 15px;
  font-weight: bold;
}

.selectAllButton {
  @include buttonPrimary;

  margin: 10px 0;
  color: $color-ui-13;
  font-weight: bold;
  font-size: 14px;
}
