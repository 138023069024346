@import "styles/modules/constants.scss";
@import "styles/mixins/index.scss";

.tooltip {
  @include tooltipShadow;

  padding: 16px 24px;
  border-radius: 10px;
  background: white;
  position: absolute;
  pointer-events: none;
  text-align: center;
}

.list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8px;
}

.item {
  display: flex;
  flex-direction: column;
  position: relative;

  &:not(:first-child) {
    margin-left: 15.5px;
    border-left: 1px solid $color-ui-17;
    padding-left: 15.5px;
  }
}

.title,
.value,
.valueSecondary,
.date {
  font-size: 14px;
  line-height: 1.43;
  white-space: nowrap;
}

.title {
  color: $color-ui-15;
}

.value,
.date {
  font-weight: 600;
  color: $color-ui-2;
}

.date {
  white-space: pre;
}

.valueSecondary {
  color: $color-ui-14;
}
