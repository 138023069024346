@import "styles/index.scss";

.table {
  width: 100%;
  margin-bottom: 14px;
  table-layout: fixed;
  font-size: 14px;
  color: $color-ui-2;
  text-align: center;

  td {
    padding: 4px 0;
    vertical-align: middle;
  }

  td:first-child {
    text-align: left;
    font-weight: 600;
  }

  td.positive {
    color: $color-ui-9;
  }

  td.negative {
    color: $color-ui-18;
  }

  td.active {
    font-weight: 600;
  }

  td.inactive {
    color: $color-ui-15;
  }

  tr:not(:last-child) td {
    border-bottom: 1px solid $color-ui-17;
  }
}
