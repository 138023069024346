@import "styles/index";

.base {
  width: 240px;
  margin: 0 auto;
}

.inputBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.inputContainer {
  position: relative;
  width: 100%;

  &[data-tooltip] {
    @include defaultTooltip(attr(data-tooltip));

    &:hover::after {
      top: unset;
      bottom: calc(100% + 5px);
    }
  }
}

.input {
  @include filterDropdownInput;
}

.inputWithClearButton.inputWithClearButton {
  padding-right: 60px;
}

.label {
  @include flex($align: center);
  @include mb(2);
}

.labelText {
  color: $color-ui-1;
}

.labelTooltip {
  @include ml(1);
  @include defaultTooltip(attr(data-tooltip));

  position: relative;

  svg {
    width: 16px;
    height: 16px;
    transform: translateY(1px);
  }

  &:hover::after {
    top: 50%;
    right: unset;
    left: calc(100% + 5px);
    transform: translateY(-50%);
    width: 150px;
  }
}

.toggleButton {
  @include resetButton;

  outline: none;
  position: absolute;
  right: 12px;
  top: 0;
  z-index: 2;
  cursor: pointer;
  color: $color-ui-5;
  height: 32px;
  display: flex;
  align-items: center;

  &:focus ~ input {
    @include inputFocus($color-ui-9);
  }

  &:active ~ input {
    @include inputActive($color-ui-9);
  }

  &[disabled] {
    @include inputDisabled;
  }
}

.clearButton {
  @include resetButton;

  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 40px;
}

.requiredSign {
  color: $color-ui-11;
  margin-left: 2px;
}
