@import "styles/index.scss";

:global(.react-tabs) {
  @include flex;

  flex-direction: column;
  border-top: 1px solid $color-ui-17;
  padding: 4px 40px 0;
  width: calc(100% + 80px);
  transform: translateX(-40px);
}

:global(.react-tabs__tab-list) {
  @include flex;

  color: $color-ui-15;
  margin-bottom: 4px;
}

:global(.react-tabs__tab) {
  @include flex($align: center);

  line-height: 32px;
  cursor: pointer;

  &[aria-selected="true"] {
    cursor: initial;
  }

  &:not(:first-child) {
    margin-left: 16px;
  }
}

.legend {
  @include flex($align: center);
}

.activeTab,
.tabPanelTitle {
  font-weight: 600;
  line-height: 30px;
  text-align: start;
}

.section {
  &:not(:first-child) {
    margin-top: 4px;
  }
}

.subSection {
  font-size: 14px;
}

.disabledTab {
  opacity: 0.4;
}

.tabTitle {
  @include resetButton;

  line-height: 32px;
  cursor: inherit;
  color: $color-ui-2;
  font-size: 16px;
  text-align: center;
}
