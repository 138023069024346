@import 'styles/modules/constants.scss';

.base {
  display: grid;
  grid-template-columns: repeat(3, auto);
  width: fit-content;
  white-space: nowrap;
}

.marginRightLarge {
  margin-right: 12px;
}

.paddingSmall {
  padding: 0 8px;
  margin-right: 8px;
}

.withSeparator {
  border-right: 1px solid $color-ui-32;
}

.arrowIncrease {
  color: $color-ui-9;
}

.arrowDecrease {
  color: $color-ui-11;
}

.arrowNeutral {
  color: $color-ui-10;
  transform: rotate(-90deg);
}
