@import "styles/mixins";

.scrollbarThumbVertical {
  @include scrollbarThumb;

  width: 7px !important; //important needed due to react-custom-scrollbar props with default styles being passed, otherwise it doesnt work properly according to documentation
  margin: 0 1px;
}

.scrollbarThumbHorizontal {
  @include scrollbarThumb;

  height: 7px !important; //important needed due to react-custom-scrollbar props with default styles being passed, otherwise it doesnt work properly according to documentation
  margin: 3px 1px 0;
}

.scrollbarTrackHorizontal {
  @include position(absolute, $right: 0, $left: 0, $bottom: 2px);

  height: 11px !important; //important needed due to react-custom-scrollbar props with default styles being passed, otherwise it doesnt work properly according to documentation
  border-radius: 3px;
  margin: 3px 1px 0;
}

.scrollbarTrackVertical {
  @include position(absolute, $top: 2px, $right: 0, $bottom: 0);

  width: 11px !important; //important needed due to react-custom-scrollbar props with default styles being passed, otherwise it doesnt work properly according to documentation
  border-radius: 3px;
}

.scrollbarHidden {
  display: none;
}
