@import "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/reportsFilterForm.module";

.base {
  margin-top: 5px;
  margin-bottom: 20px;
}

.title {
  @include choiceGroupHeader;
}

.checkbox {
  @include choiceGroupCheckbox;
}

.checkboxLabel {
  @include choiceGroupCheckboxLabel;
}

.checkboxLabelChecked {
  @include choiceGroupCheckboxLabelChecked;
}

.aggregateWrapper {
  @include flex;
}

.aggregationDropdownBase {
  @include flex($justify: space-between, $align: center);
}

.aggregationDropdownItemText {
  white-space: nowrap;
}
