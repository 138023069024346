@import 'styles/modules/reset.scss';
@import 'styles/modules/constants.scss';

.base,
.base:visited {
  cursor: pointer;
  text-decoration: none;
}

.active {
  pointer-events: none;
}
