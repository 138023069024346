@import "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/reportsFilterForm.module";

.wrapper {
  @include choiceGroupBase;
}

.title {
  @include choiceGroupHeader;
}

.radioBase {
  @include mt(2);
}
