@import "styles/index";

.dropdownInput {
  @include mt(3);
}

.checkboxBase {
  @include p(1);

  position: relative;
  flex-basis: 100%;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: darken($color-ui-7, 10%);
  }

  &:hover::after {
    position: absolute;
    top: unset;
    bottom: calc(100% + 6px);
  }
}

.checkboxInput {
  margin-right: 6px;
}

.menu {
  width: 370px;
  padding: 10px;
  background-color: $color-ui-13;
  border: 1px solid $color-ui-16;
  border-radius: 10px;
  box-shadow: 0 4px 16px 2px rgba(56, 69, 93, 0.14);
}

.attributeWrapper {
  @include flex($align: center);
}

.attributeSubItem {
  padding-left: 40px;
}

.chevronButton {
  @include flex($justify: center, $align: center);

  flex-shrink: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  width: 18px;
  height: 18px;

  svg {
    color: $color-ui-2;
    width: 100%;
    height: 100%;
    transition: color 0.1s ease-in-out;
  }

  &.open svg {
    transform: rotate(90deg);
  }
}
