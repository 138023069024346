@import "styles/index";

.labelWrapper {
  @include flex($align: center);

  margin-bottom: 2px;
}

.label {
  color: $color-ui-3;
  font-size: 14px;
}

.tooltip {
  @include defaultTooltip("Wybierz jedną miarę więcej z dostępnych do porównania w panelu bocznym");

  position: relative;
  display: block;
  margin-left: 4px;

  &:hover::after {
    top: unset;
    bottom: calc(100% + 5px);
  }

  svg {
    width: 16px;
    height: 16px;
    transform: translateY(2px);
  }
}

.select {
  color: $color-ui-2;
  border-color: $color-ui-5;
  border-radius: 3px;
  outline: 0;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
