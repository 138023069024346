@import "styles/index";

.categoryDropdownWrapper {
  @include mt(1);
}

.vendorDropdownWrapper {
  @include mt(3);
}

.timeAggregationDropdownWrapper {
  @include mt(3);
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
}

.label {
  display: block;
  color: $color-ui-1;
}

.loaderContainer {
  position: relative;
  height: 40px;
}

.loader.loader {
  @include loaderBlock();
}

.errorContainer {
  @include flex($justify: center, $align: center);

  height: 40px;
}

.errorText {
  color: $color-ui-18;
  font-size: 12px;
  text-align: center;

  & button {
    @include resetButton();

    font-weight: 900;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
