@import "styles/mixins";
@import "styles/modules/constants";

.button {
  @include buttonPrimary;
  @include flex($align: center, $justify: center);

  color: $color-ui-13;
  font-weight: bold;
  margin: 20px 0 35px;
}

.chevronRightIcon {
  font-weight: bold;
}

.textWrapper {
  margin: 30px 0 20px;
}

.regularText {
  font-size: 20px;
  text-align: center;
}

.error {
  color: $color-ui-18;
}

.retryButton {
  @include resetButton;

  cursor: pointer;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}

.loaderContainer {
  @include flex($justify: center, $align: center);
  @include mt(4);
}
