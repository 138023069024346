@import "styles/index";

.tooltip {
  @include p(4);

  box-sizing: border-box;
  background-color: $color-ui-13;
  border-radius: 14px;
  max-width: 400px;
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: $color-ui-1;
}

.content {
  color: $color-ui-2;
  line-height: 1.4;
  margin: 10px 0 20px;

  strong {
    font-weight: bold;
  }

  p:not(:last-child) {
    @include mb(1);
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    max-height: 250px;
    object-fit: contain;
  }
}

.button {
  @include buttonPrimary;
  @include buttonTextPrimary;

  width: 100%;
}
