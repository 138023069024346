@import "styles/index";

.headerButtons {
  @include flex($align: center, $justify: flex-end);
  @include mb(2);

  gap: 10px;
}

.headerButton {
  @include flex($align: center, $justify: center);

  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 18px;
  line-height: 1;
  color: $color-ui-4;
  background-color: transparent;
  border: 0;
  padding: 0;
}

.injectedContent {
  font-size: 16px;
  color: $color-ui-3;
  line-height: 1.25;
  margin-bottom: 12px;

  ul {
    list-style: disc;
    margin: 5px 0 0 18px;
  }
}

.contentButtons {
  @include flex($wrap: wrap);
  @include mb(1);

  gap: 10px;
}

.detailsButton {
  cursor: pointer;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 15px;
  color: $color-ui-9;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.linkButton {
  @include buttonPrimary;
  @include buttonTextPrimary;
  @include flex($align: center);

  width: fit-content;
  border-radius: 99px;
  height: 32px;
  padding: 10px 5px 10px 10px;
  font-size: 14px;
  text-decoration: none;
}

.spacer {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $color-ui-28;
  margin: 14px 0;
}
