@import "styles/index.scss";

$scale-size-bp: 692px;

.base {
  @include flex($direction: row);

  align-self: flex-start;
  position: sticky;
  margin-top: 40px;
  top: calc(#{$linksNavbarHeight} + 10px);
}

.column {
  @include flex($direction: column-reverse, $align: center, $justify: center);

  height: 122.5px;

  @media (min-width: $scale-size-bp) {
    height: 245px;
  }
}

.scaleColumn {
  width: 4px;

  @media (min-width: $scale-size-bp) {
    width: 8px;
  }
}

.item,
.textWrapper {
  height: 20px;

  @media (min-width: $scale-size-bp) {
    height: 40px;
  }
}

.item {
  @include flex($align: center);

  width: 4px;
  margin-top: 1px;

  &:first-child {
    border-radius: 0 0 4px 4px;
  }

  &:last-child {
    border-radius: 4px 4px 0 0;
    margin-top: 0;
  }

  &:only-child {
    border-radius: 4px;
  }

  @media (min-width: $scale-size-bp) {
    width: 8px;
  }
}

.textWrapper {
  @include flex(row, center, flex-start);

  margin-top: 1px;
}

.descriptionColumn {
  @include flex($align: flex-start);

  margin-left: 18px;
  width: auto;
}

.text {
  font-size: 12px;
  white-space: nowrap;

  @media (min-width: $scale-size-bp) {
    font-size: 14px;
  }
}

.bold {
  font-weight: bold;
}
