@import './constants.scss';

:global(svg) {
  color: inherit;
}

* {
  font-family: 'TTCommons', Arial, sans-serif;
}

body {
  margin: 0;
  font-family:
    'TTCommons',
    Arial,
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important; // overflow: auto is added to body by default with inline style. It's the only way to override it
  max-height: 100vh;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}
