@import "styles/index.scss";

.flex {
  display: inline-flex;
  width: 100%;

  @media (min-width: 1700px) {
    width: 50%;
  }
}

.chart {
  width: 100%;
  margin-top: 20px;
}

.chartName {
  font-weight: 700;
  margin-right: 40px;
}

.segmentsChart {
  display: grid;
  margin-bottom: 70px;
}

.optionsWrapper {
  @include flex($align: center);

  font-size: 15px;
  color: $color-ui-3;
}

.groupByWrapper {
  @include flex($align: center);

  position: relative;

  &:not(:last-child) {
    margin-right: 18px;
  }

  &:not(:last-child)::after {
    @include separator;
  }
}

.radio {
  margin: 0 2px 0 8px;
}

.radioLabel {
  font-size: 14px;
  color: $color-ui-3;
  margin-left: 4px;
}
