@import "styles/mixins/index";
@import "components/atoms/atoms.module.scss";

.wrapper {
  @include flex($direction: row, $align: center, $justify: space-between);
}

.toggleButton {
  flex-shrink: 0;

  &[data-disabled="true"] {
    opacity: 1;

    button {
      cursor: not-allowed;
    }
  }
}

.icon {
  color: $color-ui-3;
  width: 14px;
  height: 14px;
  transform: translateY(2px);
}
