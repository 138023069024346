@import "styles/index";
@import "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/reportsFilterForm.module";

$border: 1px solid $color-ui-16;

.wrapper {
  @include flex($align: center);

  width: auto;
}

.prefix {
  @include flex($align: center);

  color: $color-ui-3;
  font-size: 15px;
}

.categoriesWrapper {
  @include flex($direction: column);

  &:not(:last-child) {
    @include mr(2);
  }
}

.closeButton {
  @include resetButton;
  @include ml(auto);

  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;

  svg {
    color: $color-ui-2;
  }
}

.bottomWrapper {
  @include flex($justify: space-between);
  @include p(2);

  border-top: $border;
}

.acceptButton {
  @include buttonPrimary;
  @include buttonTextPrimary;

  font-size: 14px;
  min-width: 80px;
  margin-left: auto;
}

.chartDropdownGroup {
  @include flex($justify: space-around);
  @include p(3);
}

.chartDropdownMenu {
  @include position(absolute, $top: 40px, $left: 0);

  z-index: 6;
  background-color: $color-ui-13;
  width: max-content;
  min-width: 100px;
  max-width: 1200px;
  box-shadow: 0 4px 16px 2px rgba(56, 69, 93, 0.14);
  border: $border;
  border-radius: 10px;
}

.input {
  cursor: pointer;
  font-weight: 600;
}

.inputWrapper {
  @include position(relative);
}

.chevronIcon {
  @include position($position: absolute, $top: 7px, $right: 10px);

  opacity: 0.3;
  pointer-events: none;
}

.radioButton:not(:last-child) {
  @include mb(1);
}

.radioButtonLabel {
  white-space: pre-wrap;
}

.relative {
  position: relative;
}

.chartDropdownLabel {
  display: block;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 8px;
  white-space: nowrap;
}

.buttonLink {
  @include resetButton;
  @include my(1);

  display: block;
  border-radius: 0;
  min-width: auto;
  min-height: auto;
  color: $color-ui-9;
  white-space: nowrap;
  text-decoration: underline;
  position: relative;

  &:hover {
    text-decoration: none;
  }
}

.multipackGroupWrapper {
  @include pt(4);
}

.multipackGroupTable {
  th {
    font-weight: 600;
    color: $color-ui-2;
  }

  th,
  td {
    @include p(1);

    &:first-child {
      padding-left: 12px;
    }
  }

  th:not(:first-child) {
    width: 100px;
  }

  td:first-child {
    font-weight: 600;
    color: $color-ui-2;
  }

  tbody tr:hover {
    background-color: $color-ui-6;
  }
}

.multipackRadio {
  width: min-content;
  margin: 0 auto;
  transform: translateY(3px);

  div {
    margin: 0;
  }
}

.sharesWrapper {
  @include flex($align: center);
}
