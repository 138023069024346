@import "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/reportsFilterForm.module";

.base {
  @include flex($align: center);
}

.tooltipWrapper {
  @include defaultTooltip(attr(data-tooltip));
  @include ml(2);
  @include mt(2);

  cursor: pointer;
  position: relative;

  &:hover::after {
    top: auto;
    bottom: calc(100% + 10px);
  }
}

.checkbox {
  @include choiceGroupCheckbox;

  &:hover::after {
    width: auto;
  }
}

.checkboxLabel {
  @include choiceGroupCheckboxLabel;
}

.checkboxLabelChecked {
  @include choiceGroupCheckboxLabelChecked;
}
