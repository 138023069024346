@import "styles/modules/constants";
@import "styles/mixins";

.button {
  @include resetButton;

  cursor: pointer;
  font-weight: 600;
  color: $color-ui-13;
  text-decoration: underline;
}
