@import "styles/index";

.header {
  @include m(3, 0, 2);

  font-size: 20px;
  font-weight: 600;
  color: $color-ui-1;
  transition: all 0.2s ease-in-out;
}

.tiledBox {
  @include flex($direction: column);

  position: relative;
  border-radius: 14px;
  box-shadow: $defaultBoxShadow;
  border: 1px solid $color-ui-24;
  padding: 24px 32px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  background-color: $color-ui-13;

  &:hover {
    border: 1px solid $color-ui-26;
  }
}

.icon {
  display: block;
  max-width: 75%;
  width: auto;
  height: 90px;
  object-fit: contain;
  margin: 0 auto;
  flex-shrink: 0;
}

.textWrapper {
  @include flex($direction: column, $justify: center);

  height: 100%;
}

.description {
  @include mb(3);

  font-size: 16px;
  color: $color-ui-3;
}

.buttons {
  @include flex($align: center, $justify: center);
}

.button {
  @include buttonPrimary;

  color: $color-ui-13;
  font-size: 14px;
  font-weight: 600;
  max-width: 140px;
  width: 100%;

  &:not(:last-child) {
    @include mr(1);
  }
}
