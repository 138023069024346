@import "pages/Reports/partials/ReportsSidebar/ReportsFilterForm/reportsFilterForm.module";

.checkbox {
  @include choiceGroupCheckbox;
  @include mt(0);
}

.checkboxLabel {
  @include choiceGroupCheckboxLabel;
}

.checkboxLabelChecked {
  @include choiceGroupCheckboxLabelChecked;
}
