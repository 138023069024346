@import "styles/mixins";

.summaryCard {
  padding: 0;
  flex-direction: column;
  position: relative;
  width: max-content;
  min-width: 100%;

  @media (min-width: $bp-lg) {
    margin: 0;
  }
}

.headingWrapper {
  @include flex($align: center);

  padding: 30px 40px 0;
}

.warningsWrapper {
  @include ml(3);

  max-width: 880px;
}

.warning {
  @include warning;

  &:not(:last-child) {
    @include mb(2);
  }
}

.summaryContent {
  @include flex($justify: flex-start);

  padding: 30px 40px;
  width: max-content;
}

.horizontalDivider {
  display: none;

  @media (min-width: $bp-lg) {
    @include flex($align: center);
    @include position(absolute, $left: 40px);

    width: calc(100% - 40px);
  }
}

.dividerText {
  display: block;
  flex: 0 0 auto;
  color: $color-ui-34;
  white-space: nowrap;
  font-size: 14px;
  margin-right: 15px;
}

.dividerLine {
  height: 1px;
  flex: 1 1 auto;
  background-color: $color-ui-33;
}
