@import "styles/index";

.wrapper {
  @include flex($align: center);

  position: relative;

  &:not(:last-child) {
    @include mr(3);
  }

  &:not(:last-child)::after {
    @include separator;
  }
}

.label {
  @include mr(2);

  font-size: 15px;
  color: $color-ui-3;
}

.select {
  color: $color-ui-2;
  border-color: $color-ui-5;
  border-radius: 3px;
  outline: 0;
}
