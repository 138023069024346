@import "styles/index.scss";

.sectionButton {
  @include flex($align: center, $justify: space-between);

  border: 0;
  width: 100%;
  padding: 12px 24px;
}

.sectionHeaderText {
  font-weight: bold;
  font-size: 15px;
  color: $color-ui-1;
  transition: color 0.4s ease-in-out;
  max-width: 90%;
}

.filterSectionWrapper {
  border-top: 1px solid $color-ui-5;
  width: 100%;
}

.sectionContent {
  padding: 10px 32px 20px;
}

.chevronDown {
  transform: scale(1.2);
}
