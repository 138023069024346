@import "styles/mixins";
@import "styles/modules/constants";

.base {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-ui-7;
}

.cardWrapper {
  width: 100%;
  height: auto;
  max-width: 616px;
  max-height: 514px;
  overflow: hidden;
  border-radius: 14px;
  box-shadow: $defaultBoxShadow;
  border: $loginFormCardBorder;
}

.card {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
  box-shadow: none;
  padding-right: 17px;
  padding-bottom: 17px;
  box-sizing: content-box;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
}

.logo {
  width: 140px;
  height: 54.7px;
}

.header {
  padding-top: 17px;
  font-size: 18px;
  letter-spacing: 1.1px;
  color: $color-ui-8;
}
