@import "styles/index";

.button {
  @include buttonPrimary;
  @include buttonTextPrimary;

  font-size: 14px;
}

.overlay {
  @include flex($align: center, $justify: center);
  @include p(3);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: $color-ui-23;
}

.content {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
}
