@import "styles/index";

$dropdown-shadow: 0 4px 16px 2px rgba(56, 69, 93, 0.14);

.inputWrapper {
  @include position(relative);
}

.input {
  cursor: pointer;
  font-weight: 600;
}

.chevronIcon {
  @include position($position: absolute, $top: 7px, $right: 10px);

  opacity: 0.3;
  pointer-events: none;
}

.dropdownWrapper {
  @include position($position: absolute, $top: calc(100% + 5px), $left: 0);

  width: 250px;
  border: 1px solid $color-ui-29;
  background-color: $color-ui-13;
  box-shadow: $dropdown-shadow;
  border-radius: 10px;
  z-index: 5;
}

.dropdownList {
  list-style-type: none;
}

.actionButtons {
  @include flex($justify: space-between);

  border-bottom: 1px solid $color-ui-29;
  padding: 8px 16px;
}

.actionButton {
  @include resetButton;

  margin: 0 3px;
  outline: none;
  display: block;
  cursor: pointer;
}

.actionButtonDisabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.actionButtonText {
  font-weight: bold;
  color: $color-ui-3;
  font-size: 14px;
}

.dropdownElement {
  @include position(relative);

  border-top: 1px solid $color-ui-16;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: darken($color-ui-7, 10%);
  }

  &:first-child {
    border-top: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.blockedDropdownElement {
  @include defaultTooltip(attr(data-tooltip));

  position: relative;

  &:hover::after {
    top: -25px;
    pointer-events: none;
  }
}

.radioButtonBase {
  padding: 8px 16px;
}

.dropdownElementGradient {
  &::after {
    @include position(absolute, $top: 0, $right: 0);

    content: "";
    height: 100%;
    width: 25px;
    opacity: 0.7;
    background: linear-gradient(90deg, transparent, var(--gradient-color));
  }
}

.noDataTypesLabel {
  padding: 10px;
}

.relative {
  position: relative;
}
