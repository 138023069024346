@import "styles/index";

$wrapperPadding: 32px;
$locationTotalPadding: 16px;

.wrapper {
  @include flex($align: center);

  padding: 18px $wrapperPadding 12px;
}

.secondChoiceGroupWrapper {
  margin-left: -#{$wrapperPadding};
}

.thirdChoiceGroupWrapper {
  border-left: 1px solid $color-ui-17;
}

.exportWrapper {
  margin-left: auto;
  flex: 0 0 auto;
  padding: 18px 25px 12px;
  border-left: 1px solid $color-ui-17;
}

.prefix {
  @include flex($align: center);

  padding-right: $wrapperPadding;
  color: $color-ui-3;
  font-size: 15px;
}

.item {
  color: $color-ui-3;
  white-space: nowrap;

  &:not(:last-child) {
    padding-right: 18px;
  }
}

.downloadButton {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  position: relative;
}

// location total aggregation specific styles
.locationTotal {
  &.wrapper {
    padding: 18px $locationTotalPadding 12px;
  }

  &.secondChoiceGroupWrapper {
    margin-left: -#{$locationTotalPadding};
  }

  &:not(.thirdChoiceGroupWrapper) .prefix {
    padding-right: 15px;
  }
}
