@import "styles/index";

.container {
  @include flex($direction: column);

  position: relative;
  padding: 12px 16px 24px;
  background-color: $color-ui-13;
  border: 1px solid $color-ui-24;
  border-radius: 14px;
}

.containerHidden {
  display: none;
}

.newBadge {
  &::before {
    content: "Nowy";
    position: absolute;
    top: 0;
    right: -5px;
    transform: translateY(-60%);
    text-transform: uppercase;
    background-color: $color-ui-9;
    color: $color-ui-13;
    font-weight: 600;
    padding: 6px 8px 4px;
    border-radius: 5px;
  }
}

.header {
  @include flex($align: flex-start, $justify: space-between);
}

.headingWrapper {
  @include flex($align: center, $justify: space-between);
  @include mb(3);
}

.index {
  @include mr(2);

  flex-shrink: 0;
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 30px;
  border-radius: 50%;
  font-weight: 600;
  text-align: center;
  color: $color-ui-25;
  background-color: transparentize($color-ui-9, 0.7);
  box-shadow: $defaultBoxShadow;
}

.headerButtons {
  @include flex($align: center, $justify: flex-end);

  flex-shrink: 0;
}

.headerButton {
  @include flex($align: center, $justify: center);

  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 18px;
  line-height: 1;
  color: $color-ui-4;
  background-color: transparent;
  border: 0;
  padding: 0;

  &:not(:last-child) {
    @include mr(2);
  }
}

.title {
  @include mr(1);

  display: block;
  font-size: 24px;
  font-weight: 600;
  color: $color-ui-1;
  line-height: 1;
}

.insightHtmlInjectedContent {
  font-size: 16px;
  color: $color-ui-3;
  line-height: 1.25;
  margin: 12px 0 18px;

  ul {
    list-style: disc;
    margin: 5px 0 0 18px;
  }
}

.buttons {
  @include flex($align: center, $justify: space-between);

  margin-top: auto;
}

.linkButton {
  @include buttonPrimary;
  @include buttonTextPrimary;
  @include flex($align: center);

  width: fit-content;
  padding: 12px 8px 12px 18px;
  border-radius: 99px;
  text-decoration: none;

  svg {
    @include ml(1);
  }
}

.spacer {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $color-ui-28;
  margin: 18px 0;
}

.footer {
  @include flex($align: center, $justify: space-between);

  min-height: 28px;
}

.feedback {
  display: block;
  font-size: 14px;
  color: $color-ui-3;
  line-height: 1.15;
  max-width: 70%;
}

.voteButton {
  cursor: pointer;
  flex-shrink: 0;
  padding: 0;
  border: 0;
  background-color: transparent;

  svg {
    fill: $color-ui-4;
    transition: fill 0.1s ease;
  }

  &:nth-child(1):hover svg {
    fill: $color-ui-9;
  }

  &:nth-child(2):hover svg {
    fill: $color-ui-18;
  }

  &:not(:last-child) {
    @include mr(3);
  }
}

.answerButton {
  @include buttonPrimary;
  @include flex($align: center);

  cursor: pointer;
}

.answerText {
  font-size: 14px;
  font-weight: 600;
  color: $color-ui-13;
}

.infoWarning {
  display: inline-block;
  max-width: 40vw;
  width: max-content;
  text-align: center;
  color: $color-ui-25;
  padding: 10px 24px;
  background-color: transparentize($color-ui-9, 0.7);
  border-radius: 50px;
  font-size: 14px;
  box-shadow: $defaultBoxShadow;
}

.tooltip {
  @include defaultTooltip(attr(data-tooltip));

  position: relative;

  &:hover::after {
    top: -25px;
    pointer-events: none;
    z-index: 10;
    width: max-content;
  }
}

.detailsButton {
  all: unset;
  cursor: pointer;
  font-size: 15px;
  color: $color-ui-9;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
