@import "styles/mixins/index.scss";

.base {
  font-weight: 600;
  display: block;

  &:hover {
    text-decoration: underline;
  }
}

.active {
  text-decoration: underline;
}

.sortButton {
  @include resetButton;

  position: relative;
  display: block;
  cursor: pointer;
  text-align: left;
}

.iconWrapper {
  @include position(absolute, -1px, -20px);
}

.tooltipIconWrapper {
  @include defaultTooltip(attr(data-tooltip));
  @include position(relative, $top: -2px);

  display: inline-block;
  cursor: pointer;

  &:hover::after {
    width: 125px;
    padding: 6px 14px;
    transform: translateX(20%);
    top: 28px;
  }
}

.tooltipIcon {
  transform: scale(0.6);
}
