@import "styles/modules/constants";
@import "styles/mixins/index";

.labelText {
  color: $color-ui-1;
  display: block;
  margin-bottom: 8px;
}

.requiredSign {
  color: $color-ui-11;
  margin-left: 2px;
}

.tierChoiceWrapper {
  margin-bottom: 8px;
}

.toggleButtonLi {
  flex-grow: 1;
  height: 35px;
  min-width: 30px;

  &:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  &:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

.toggleButton {
  width: 100%;
  padding: 8px 0;

  &.active {
    color: $color-ui-2;
  }
}
