@import "styles/index";

.canvas {
  max-width: 600px;
  margin-top: auto;
}

.label {
  @include flex($direction: column, $align: center);
}

.labelText {
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}
