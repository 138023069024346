@import 'styles/mixins';

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
}

.countyDropdownWrapper {
  @include mt(1);
}
