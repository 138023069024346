@import "styles/mixins";

.mainInfoText {
  font-size: 14px;
  color: $color-ui-1;
}

.vendorId {
  color: $color-ui-3;
  padding: 3px 0 3px;
}

.competitorVendorId {
  color: $color-ui-1;
  padding: 3px 0 3px;
  font-style: italic;
}

.promotion {
  color: $color-ui-9;
}

.contentCell {
  white-space: nowrap;
}

.lastYearContent {
  color: $color-ui-39;
}

.valueType {
  color: $color-ui-39;
}

.dataCell {
  @include flex($align: center);

  cursor: pointer;
  padding: 12px 8px 12px 8px;
  user-select: none;
  height: 100%;
  background-color: transparent;
  scroll-margin-top: 66px;
}

.dataCellHighlighted {
  transition: background-color 0.12s ease-in-out;
  background-color: $color-ui-26;
}

.hiddenLinesCell {
  opacity: 0.35;
}

.noCopyText {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.noLeftPadding {
  padding-left: 0 !important;
}
