@import "styles/index";

.errorContainer {
  @include flex($justify: center, $align: center);

  margin-top: 8px;
  height: 40px;
}

.errorText {
  color: $color-ui-18;
  font-size: 12px;
  text-align: center;

  .button {
    @include resetButton();

    font-weight: 900;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
