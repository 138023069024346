@import "styles/index";

.pinButton {
  background-color: $color-ui-13;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
}

.chartPinTooltip {
  @include position(absolute, $top: 6px);
}

.pinWrapper {
  padding: 5px 4px 2px 5px !important;
}
