@import 'styles/modules/constants.scss';
@import 'styles/mixins';

.userManagementPopupContainer {
  padding: 48px 56px;
  max-width: 612px;
  width: 100%;
  height: auto;
  max-height: 90vh;
  overflow: auto;
  border-radius: 14px;
  background-color: $color-ui-13;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

.date {
  font-size: 14px;
  color: $color-ui-31;
  margin-bottom: 24px;
  display: block;
}

.content {
  font-size: 18px;
  line-height: 1.28;
  display: block;
  color: $color-ui-2;
  white-space: pre-wrap;
}

.buttonsWrapper {
  @include flex($align: center);

  margin-top: 24px;
}

.center {
  justify-content: center;
}

.actionButton {
  @include buttonPrimary;
  @include flex($align: center, $justify: center);

  color: $color-ui-13;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 10px 6px 45px;
  border-radius: 30px;
}

.cancelButton {
  border: 0;
  margin-left: 30px;
  font-weight: bold;
  font-size: 16px;
  color: $color-ui-3;
  cursor: pointer;
  background-color: transparent;
}

.chevronRightIcon {
  margin-left: 20px;
  margin-bottom: 3px;
  font-weight: bold;
}

.userName {
  font-weight: bold;
}

.errorText {
  flex-basis: 100%;
  display: flex;
  padding-left: 22px;
  padding-top: 4px;
  font-size: 14px;
  font-weight: 600;
  color: $color-ui-11;
}
