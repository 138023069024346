@import "styles/mixins/index";

.base {
  padding-left: 24px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  border-radius: 16px;
}

.text {
  font-size: 14px;
}

.button {
  @include alertButton;
}

.criticalAlert {
  @include criticalAlert;
}

.criticalAlertText {
  @include criticalAlertText;
}

.criticalAlertButton {
  @include criticalAlertButton;
}

.successAlert {
  @include successAlert;
}

.successAlertText {
  @include successAlertText;
}

.successAlertButton {
  @include successAlertButton;
}

.icon {
  @include closeIconPrimary;
}
