@import "styles/mixins";
@import "styles/modules/constants";

.wrapper {
  @include flex($direction: column);
}

.button {
  @include buttonPrimary;
  @include flex($align: center, $justify: center);

  color: $color-ui-13;
  font-weight: bold;
  margin: 20px auto 100px;
  min-width: 220px;
  width: 25%;
}

.chevronRightIcon {
  font-weight: bold;
}

.contentWrapper {
  position: relative;
  background-color: $color-ui-7;
  color: $color-ui-2;
  max-height: calc(100vh - 83px);
}

.contentWrapperWithTopSpace {
  top: 83px;
}

.content {
  margin: 20px 15px;
  padding: 30px;
  border-radius: 14px;
  border: solid 1px $color-ui-24;
  box-shadow: $defaultBoxShadow;
  background-color: $color-ui-13;
  max-height: calc(100vh - 255px);
  overflow: auto;

  // tags used for html injection from endpoint
  h3 {
    font-weight: bold;
    font-size: 23px;
    margin-bottom: 24px;
    line-height: 1.43;
  }

  p {
    color: $color-ui-2;
    font-size: 16px;
    line-height: 1.43;
    margin-bottom: 15px;
  }
}

.footer {
  @include flex($justify: flex-start);
  @include position(fixed, $bottom: 0, $left: 0);

  width: 100%;
  padding: 25px 20px;
  background-color: $color-ui-13;
  border-top: solid 1px $color-ui-24;
  box-shadow: $defaultBoxShadow;
}
