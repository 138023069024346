@import "styles/modules/constants.scss";
@import "styles/mixins/index.scss";
@import "styles/index.scss";

.base {
  width: 100%;
}

.inputContainer {
  position: relative;
  width: 100%;
}

.input {
  @include filterDropdownInput;

  width: 100%;
  color: $color-ui-2;
  border-color: $color-ui-5;
  background-color: $color-ui-7;
}

.inputWithClearButton {
  padding-right: 60px;
}

.label {
  display: block;
  margin-bottom: 8px;
}

.labelText {
  color: $color-ui-1;
}

.toggleButton {
  @include resetButton;

  position: absolute;
  right: 12px;
  top: 0;
  height: 32px;
  outline: none;
  z-index: 2;
  cursor: pointer;
  color: $color-ui-5;
  display: flex;
  align-items: center;

  &:focus ~ input {
    @include inputFocus($color-ui-9);
  }

  &:active ~ input {
    @include inputActive($color-ui-9);
  }

  &[disabled] {
    @include inputDisabled;
  }
}

.clearButton {
  @include resetButton;

  position: absolute;
  right: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
}
