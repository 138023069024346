@import "styles/mixins";

.toggleWrapper {
  @include flex($align: center);

  padding: 30px 40px 20px;
  border-bottom: 1px solid $color-ui-17;
  font-size: 14px;
  color: $color-ui-31;
}

.toggleWrapperSecondary {
  padding-top: 15px;
  padding-bottom: 15px;
}

.toggleButtonsWrapper {
  @include flex();
}

.toggleButtonWrapper {
  @include flex($align: center);

  &:not(:last-child) {
    @include mr(3);
  }
}

.toggleTitle {
  @include mr(3);

  font-size: 14px;
  color: $color-ui-31;
}

.toggleButton {
  border: 0;
  font-size: 14px;
  color: $color-ui-31;
  cursor: pointer;
  text-align: center;
  outline: 0;
  padding: 0;
  background-color: transparent;
  position: relative;

  &:hover {
    color: $color-ui-2;
  }

  &:disabled {
    @include defaultTooltip(attr(data-tooltip));

    cursor: not-allowed;

    &:hover {
      color: $color-ui-31;
    }
  }
}

.toggleButtonActive {
  font-weight: bold;
  color: $color-ui-2;
  transform: translateY(1px);
}

.tooltip {
  @include defaultTooltip(attr(data-tooltip));
  @include ml(2);

  position: relative;

  &:hover::after {
    top: unset;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }
}
