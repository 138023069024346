@import "styles/index";

.base {
  @include flex($justify: flex-end);
  @include position(relative, $top: 0);

  transition: all $filtersAndPromotionsBarsTransitionTime $springLikeTransition;
  min-height: calc(100vh - 83px);
}

.shrinkedBase {
  max-height: calc(100vh - 83px);
  overflow: hidden;

  @media (min-width: $bp-xl) {
    max-height: unset;
    overflow: visible;
    padding-left: $sidebarWidth;
  }
}

.offsetBase {
  top: 64px;
}

.layout {
  @include p(4, 4, 5);

  background-color: $color-ui-7;
  width: 100%;
}

.overlay {
  @include position(absolute, $top: 0, $left: 0);

  background-color: $color-ui-23;
  width: 100%;
  height: 100%;
  z-index: 5;

  @media (min-width: $bp-xl) {
    display: none;
  }
}
