@import "styles/mixins";
@import "styles/modules/constants";

$box-shadow: 0 6px 30px 5px rgba(56, 69, 93, 0.12),
  0 16px 24px 2px rgba(56, 69, 93, 0.14), 0 0 0 1px rgba(146, 155, 170, 0.12);

.errorAlert {
  @include flex($align: center);

  position: fixed;
  top: 20px;
  right: 20px;
  width: auto;
  max-width: 90vw;
  height: auto;
  z-index: 25;
  padding: 15px;
  box-shadow: $box-shadow;
  background-color: $color-ui-13;
  border: 2px solid $color-ui-11;
}

.errorInterior {
  @include flex($align: center);

  padding: 0 15px 0 0;
  font-size: 17px;

  a {
    color: $color-ui-11;

    &:hover {
      text-decoration: none;
    }
  }
}

.alertIcon {
  margin: 0 15px 0 10px;
  min-width: 20px;
}

.closeIcon {
  min-width: 20px;
  margin: 0 10px 0 0;
}
