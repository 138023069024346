@import 'styles/modules/constants.scss';
@import 'styles/index.scss';

.base {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.primary {
  color: $color-ui-2;
}

.secondary {
  color: $color-ui-4;
}

.success {
  color: $color-ui-8;
}

.brand {
  color: $color-ui-9;
}

.critical {
  color: $color-ui-11;
}

.white {
  color: $color-ui-13;
}

.info {
  color: $color-ui-10;
}

.label {
  color: $color-ui-14;
}

.small {
  font-size: 15px;
}

.extraSmall {
  font-size: 14px;
}

.bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}
