@import "styles/modules/constants.scss";

$stickyColumnShadow: 2px 5px 5px $color-ui-37;

.table {
  border-collapse: separate;
  width: 100%;
}

.tableHeaderRow {
  background-color: $color-ui-6;

  th {
    border-top: none;
    border-left: none;
    border-right: none;
    padding-right: 25px;
    padding-left: 8px;
  }

  [data-sticky-last-left-td] {
    box-shadow: $stickyColumnShadow;
    background-color: $color-ui-6;
  }

  &:first-child {
    th:first-child {
      border-radius: 14px 0 0 0;
    }

    th:last-child {
      border-radius: 0 14px 0 0;
    }
  }
}

.tableRow {
  border: 1px solid $color-ui-21;
  background-color: $color-ui-13;

  &:nth-child(2n) {
    background-color: $color-ui-7;
  }

  [data-sticky-last-left-td] {
    box-shadow: $stickyColumnShadow;
    background-color: $color-ui-35;
  }

  td {
    border-top: none;
    padding-left: 8px;
  }

  td:not(:first-child) {
    border-left: none;
  }

  td:not(:last-child) {
    border-right: none;
  }

  &:last-child {
    td:first-child {
      border-radius: 0 0 0 14px;
      border-right: none;
    }

    td:last-child {
      border-radius: 0 0 14px 0;
      border-left: none;
    }
  }
}

.tableCell {
  text-align: left;
  padding: 24px 0 16px;
  border: 1px solid $color-ui-21;

  &:first-child {
    padding-left: 32px;
  }

  &:last-child {
    padding-right: 32px;
  }
}
