@import "styles/modules/constants";
@import "styles/mixins";

$cell-radius: 4px;

.CalendarDayCustomContent {
  @include flex($align: center, $justify: center);

  height: 100%;
}

.DayPicker {
  box-shadow: none;

  &_weekHeader {
    padding: 0 0 14px !important; //overriding inline styles applied by airbnb calendar
    margin: 0 3px;
    width: 273px;
    border-bottom: 1px solid $color-ui-28;

    &:last-child {
      left: 300px !important; //overriding inline styles applied by airbnb calendar
    }
  }
}

.DateRangePicker_picker {
  top: 42px !important; //overriding inline styles applied by airbnb calendar

  .DayPicker {
    // Disable `overflow: hidden` to render week numbers properly
    &_transitionContainer {
      overflow: visible;
    }

    // Additional "Tydz." header for left calendar
    &_weekHeader_ul::before {
      content: "Tydz.";
      color: $color-ui-1;
      font-style: italic;
      position: absolute;
      right: 100%;
    }

    // Additional "Tydz." header for right calendar
    &_weekHeader:nth-child(2) .DayPicker_weekHeader_ul::before {
      left: 100%;
    }
  }
}

.CalendarDay {
  vertical-align: middle;
  border: 0;
  height: 28px !important; //overriding inline styles applied by airbnb calendar
  position: relative;

  &:focus {
    outline: 0;
  }

  &__hovered {
    &_span {
      background-color: $color-ui-7;
      color: $color-ui-3;
    }
  }

  &__blocked_out_of_range {
    border: none;
    background-color: $color-ui-13;
    cursor: not-allowed;

    &:hover {
      border: none;
    }
  }

  &__selected {
    background-color: $color-ui-9;
    border-radius: $cell-radius;
    border: 0;

    &_span {
      background-color: $color-ui-7;

      .CalendarDayCustomContent {
        border-top: 1px solid $color-ui-5;
        border-bottom: 1px solid $color-ui-5;
        background-color: $color-ui-7;
        color: $color-ui-3;
      }
    }

    &_start,
    &_end {
      .CalendarDayCustomContent {
        @include flex($align: center, $justify: center);

        background-color: $color-ui-9;
        position: relative;
        z-index: 2;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: $cell-radius;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 1px);
        height: calc(100% - 2px);
        background-color: $color-ui-7;
        border: 1px solid $color-ui-5;
        border-right: 0;
        border-bottom-left-radius: $cell-radius;
        border-top-left-radius: $cell-radius;
        z-index: 1;
      }
    }

    &_end {
      .CalendarDayCustomContent {
        right: 1px;
        border-radius: $cell-radius - 1;
      }

      &::after {
        border-left: 0;
        right: 1px;
        border-bottom-right-radius: $cell-radius;
        border-top-right-radius: $cell-radius;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  &:hover:not(&__blocked_out_of_range) {
    background-color: $color-ui-5;
    color: $color-ui-13;
    border-radius: $cell-radius;
    border: 0;
  }

  &__today {
    .CalendarDayCustomContent {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: solid 1px #a6b7d3;
    }
  }
}

.CalendarMonth {
  &_caption {
    text-align: center;
    font-size: 15px;
    text-transform: capitalize;
    font-weight: bold;
  }

  &_table {
    margin-top: 28px;
  }

  .CalendarWeekNumber {
    color: $color-ui-1;
    font-style: italic;
    text-align: center;
    font-weight: normal;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 28px;
  }
}

.CalendarMonthGrid {
  &__horizontal {
    left: 0;
  }

  &_month__horizontal {
    // Push week numbers to the left side for left calendar
    &:nth-child(1) .CalendarWeekNumber,
    &:nth-child(2) .CalendarWeekNumber {
      right: auto !important;
    }

    // Push week numbers to the right side for right calendar
    &:nth-child(3) .CalendarWeekNumber,
    &:nth-child(4) .CalendarWeekNumber {
      left: auto !important;
    }
  }
}

.DayPickerNavigation {
  &__horizontal {
    width: 594px !important; //overriding inline styles applied by airbnb calendar
  }

  &_button {
    position: absolute;
    top: 18px;

    &:first-child {
      left: 23px;
    }

    &:last-child {
      right: 23px;
    }
  }
}

.DateInput {
  position: relative;
  display: inline-flex;
  align-items: baseline;
  justify-content: flex-start;

  &_input {
    padding: 10px 72px 10px 25px;
    font-size: 15px;
    color: $color-ui-2;
    border-radius: 20px;
    border: 1px solid $color-ui-16;
    max-height: 40px;
    margin-left: 15px;
    width: 270px;
  }

  &_fang {
    display: none;
  }
}

.DateRangePickerInput {
  &__withBorder {
    border: 0;
    width: 100%;
  }

  .DateInput {
    &:first-child {
      &::before {
        content: "Od:";
        width: 25px;
        height: 15px;
        font-size: 15px;
        font-weight: bold;
        margin-left: 20px;
      }
    }

    &:last-child {
      margin-left: 194px;

      &::before {
        content: "Do:";
        width: 25px;
        height: 15px;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
}

.DateRangePicker {
  .DayPickerNavigation {
    &__horizontal {
      width: 594px !important; //overriding inline styles applied by airbnb calendar
    }

    &_button {
      position: absolute;
      top: 18px;

      &:first-child {
        left: 23px;
      }

      &:last-child {
        right: 23px;
      }
    }
  }
}

.DateRangePickerInput_arrow {
  display: none;
}
