@import "styles/mixins";
@import "styles/modules/constants";

.base {
  @include mr(5);

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.headingWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 15px 0;
  width: max-content;

  @media (min-width: $bp-lg) {
    margin: 15px 0 27px;
  }
}

.label {
  @include mb(auto);

  white-space: break-spaces;
  flex-grow: 2; //label should take space it needs, so summary main values always stay in 1 horizontal line
}

.marginRight {
  margin-right: 12px;
}

.headingValue {
  display: block;
  white-space: nowrap;
}

.headingValueBottom {
  font-size: 17px;
}

.tooltipIconWrapper {
  @include defaultTooltip(attr(data-tooltip));

  position: relative;
  cursor: pointer;

  &:hover::after {
    width: 150px;
    padding: 6px 14px;
    transform: translateX(45%);
    top: auto;
    bottom: calc(100% + 10px);

    @media (min-width: $bp-popular) {
      width: 260px;
    }
  }
}

.tooltipIcon {
  @include position(absolute, $top: -3px, $left: 1px);

  transform: scale(0.8);
}

.labelWithTooltip {
  @include flex();
}
