@import "styles/index";

$border: 1px solid $color-ui-16;

.base {
  min-width: 240px;
  background-color: $color-ui-13;
  overflow: hidden;
  box-shadow: 0 4px 16px 2px rgba(56, 69, 93, 0.14);
  border: $border;
  border-radius: 10px;
  z-index: 4;
}

.calendarSection {
  @include flex($direction: column);

  padding: 1em 2em;
  border-top: 1px solid $color-ui-29;
  z-index: 2;
}

.calendarFooter {
  @include flex($direction: row, $align: center);
}

.warnings {
  max-width: 410px;
  width: fit-content;

  & span:not(:last-child) {
    @include mb(2);
  }
}

.baseShort {
  width: 565px;

  .calendarSection {
    padding: 1em;
  }

  .calendarFooter {
    flex-direction: column;
  }

  .warnings {
    @include mb(2);
  }
}

.listWrapper {
  @include flex($justify: space-between);

  width: auto;
  height: fit-content;
  min-height: 380px;
  box-sizing: content-box;
}

.list {
  list-style-type: none;
  padding: 25px 0;
}

.item {
  position: relative;
  min-height: 32px;
  padding: 8px 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 50px;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: darken($color-ui-7, 10%);
  }
}

.iconContainer {
  position: absolute;
  left: 25px;
}

.itemsCoutner {
  position: relative;
  min-height: 32px;
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  transition: background-color 0.1s ease-in-out;
  border-bottom: 1px solid $color-ui-16;
}

.itemsCoutnerText {
  font-size: 12px;
  color: $color-ui-14;
}

.itemsCounterButton {
  @include resetButton;

  outline: none;
  display: block;
  cursor: pointer;
}

.itemsCounterButtonText {
  font-weight: 600;
  color: $color-ui-3;
  font-size: 14px;
}

.dateRangeCalendarWrapper {
  @include flex($direction: column);

  overflow: hidden;
}

.calendarWrapper {
  width: 600px;
  height: 330px;
  padding: 20px 0;

  @media (min-width: $bp-lg) {
    width: 680px;
    padding: 20px 40px;
  }
}

.calendarWrapperShort {
  @include flex($direction: row, $justify: center);

  width: 340px;

  @media (min-width: $bp-lg) {
    padding: 20px 0;
  }
}

.calendarButtons {
  @include flex;
  @include ml(auto);
}

.calendarError {
  display: block;
  padding: 7px;
  background-color: $color-ui-11;
  color: $color-ui-13;
  font-weight: bold;
  border-radius: 10px;
  font-size: 13px;
  flex: 1;
  max-width: max-content;
  text-align: center;
}

.acceptButton {
  @include buttonPrimary;
  @include flex($align: center, $justify: center);

  color: $color-ui-13;
  font-size: 14px;
  font-weight: 600;
}

.chevronRightIcon {
  margin-right: -10px;
  margin-bottom: 2px;
  font-weight: bold;
}

.cancelButton {
  color: $color-ui-3;
  font-size: 16px;
  font-weight: bold;
  border: 0;
  margin-right: 10px;
}

.presetsWrapper {
  border-left: 1px solid $color-ui-29;
  width: 190px;

  @media (min-width: $bp-lg) {
    min-width: 200px;
  }
}

.presetsTitle {
  margin: 24px 0 -10px 32px;
  font-size: 15px;
  font-weight: bold;
  display: block;
}

.alert {
  @include p(1, 2);

  display: block;
  font-size: 14px;
  border-radius: 5px;

  &.success {
    color: $color-ui-8;
    background-color: rgba($color-ui-8, 0.1);
  }

  &.error {
    color: $color-ui-11;
    background-color: rgba($color-ui-11, 0.1);
  }
}
